
/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

@include mat.all-component-typographies();
@include mat.core();

.width100 {
  width: 100%;
}

h1 {
  width: 100%;
}

.txtCenter {
  text-align: center;
}

html *
{
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
}

$my-typography: mat.define-typography-level(
  $font-family: Roboto,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$pink-palette),
   accent: mat.define-palette(mat.$blue-grey-palette),
 ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

$light-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$indigo-palette),
   accent: mat.define-palette(mat.$pink-palette),
 ),
 // Only include `typography` and `density` in the default dark theme.
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($light-theme);

